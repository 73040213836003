import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import "../styles/blog.css"

class BlogList extends React.Component {
  render() {
    const { data, location } = this.props
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? "/blog/" : (currentPage - 1).toString()
    const nextPage = "/blog/" + (currentPage + 1).toString()

    return (
      <Layout location={location}>
        <section className="blog">
          <div className="container">
            <div className="blog-block">
              <h3 className="blog-title">Наши статьи</h3>
              <div className="blog-content">
                {posts.map(({ node }) => {
                  const title = node.frontmatter.title || node.fields.slug
                  const img = node.frontmatter.image.childImageSharp.fluid
                  return (
                    <div className="blog-card" key={node.fields.slug}>
                      <Img className="blog-card__img" fluid={img} alt=""/>
                      <div className="blog-card__descriptions">
                        <span className="blog-card__date">
                          {node.frontmatter.date}
                        </span>
                        <h5 className="blog-card__head">{title}</h5>
                        <div className="blog-cart__text">
                          {node.frontmatter.description}
                        </div>
                        <div className="blog-card__link">
                          <Link
                            to={`/blog/${node.fields.slug}`}
                            className="blog-card__link-style"
                          >
                            Читать далее
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {numPages > 1 && (
                <div className="blog-pagination">
                  <ul className="blog-pagination__list">
                    {!isFirst && (
                      <Link to={prevPage} rel="prev" className="blog-pagination__list_prev">
                        &#10094;
                      </Link>
                    )}
                    {Array.from({ length: numPages }, (_, i) => {
                      const isCurrent = currentPage === i + 1

                      let link = !isCurrent ? (
                        <Link to={`/blog/${i === 0 ? "" : i + 1}`}>{i + 1}</Link>
                      ) : (
                        i + 1
                      )

                      return (
                        <li
                          key={`pagination-number${i + 1}`}
                          className={currentPage ? "current" : ""}
                        >
                          <span>{link}</span>
                        </li>
                      )
                    })}
                    {!isLast && (
                      <Link to={nextPage} rel="next" className="blog-pagination__list_next">
                        &#10095;
                      </Link>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(blog)/.*\\\\.md$/"}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
